import React from 'react'
//import { Link } from 'gatsby'

import LandingSections from '../components/LandingSections'

const IndexPage = () => (
  <LandingSections location="Moncton" />
)

//<Image />

export default IndexPage
